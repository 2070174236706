import { Chip, FormControl, FormLabel, Tooltip } from "@material-ui/core";
// @material-ui/core
import { createStyles, withStyles } from "@material-ui/core/styles";
import person from "assets/img/person_placeholder.png";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import Images from "components/common/Images/Images";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getUserFullname } from "utils/Utils";
import "./PassGuests.scss";

class PassGuests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteWarning: false,
    };

    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  renderUsers() {
    const { pass, classes, friends } = this.props;
    let users = [];
    friends.forEach((friend) => {
      "guests" in pass &&
        pass.guests.forEach((guest) => {
          if (friend.id === guest && !this.isRemoved(friend)) {
            let facePhoto = person;
            if (friend.facePhoto) {
              facePhoto = process.env.REACT_APP_IMAGES_URL + friend.facePhoto;
            }

            users.push(
              <Chip
                key={friend.id}
                onDelete={() => this.onDeleteConfirmation(friend)}
                avatar={
                  <Tooltip title={getUserFullname(friend)}>
                    <div className={classes.chip}>
                      <Images
                        src={facePhoto}
                        class_name={classes.avatar}
                        avatar="1"
                      />
                    </div>
                  </Tooltip>
                }
              />
            );
          }
        });
    });

    return <div className={classes.users}> {users} </div>;
  }

  isRemoved(guest) {
    const { removeGuests } = this.props;
    for (let i = 0; i < removeGuests.length; i++) {
      if (removeGuests[i].id === guest.id) {
        return true;
      }
    }
    return false;
  }

  onClose() {
    this.setState({
      showDeleteWarning: false,
    });
  }

  onDeleteConfirmation(guest) {
    this.setState({
      showDeleteWarning: true,
      guestToRemove: guest,
    });
  }

  onDelete() {
    const { guestToRemove } = this.state;
    this.props.onGuestRemoved(guestToRemove);
    this.setState({
      showDeleteWarning: false,
    });
  }

  render() {
    const { t } = this.props;
    const { guestToRemove, showDeleteWarning } = this.state;

    return (
      <div>
        <ConfirmationDialog
          open={showDeleteWarning}
          onConfirm={this.onDelete}
          onClose={this.onClose}
          title={t("delete_user", { user: getUserFullname(guestToRemove) })}
        />

        <FormControl className="pass-guests" component="fieldset">
          <FormLabel>{t("guest_amount")}</FormLabel>
          {this.renderUsers()}
        </FormControl>
      </div>
    );
  }
}
const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  users: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: "-13px",
  },
  chip: {
    width: "40px !important",
    height: "40px !important",
    marginLeft: "0px !important",
    marginRight: "-13px !important",
  },
}));

const mapStateToProps = (state) => ({
  friends: state.userReducer.friends,
});

export default connect(mapStateToProps)(
  withTranslation()(withStyles(useStyles)(PassGuests))
);
